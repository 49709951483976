import styled from 'styled-components';

import device from '../../device';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';

const PageHeader = styled.header`
  height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled.h1`
  /* margin-bottom: ${layoutSize[6]}; */
  font-size: ${fontSize[8]};
  text-align: center;
  @media ${device.tablet} {
    font-size: ${fontSize[9]};
  }
`;

export default PageHeader;
