import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Button, CTAButton } from '../components/styled/Buttons';
import Container from '../components/styled/Container';
import device from '../device';
import fontSize from '../fontSize';
import Layout from '../components/Layout';
import layoutSize from '../layoutSize';
import PageHeader, { PageTitle } from '../components/styled/PageHeader';
import SectionTitle from '../components/styled/SectionTitle';
import ProjectImg from '../components/Image/ProjectImg';
import DatePickerGIF from '../images/Rumer/Rumer_datepicker.gif';

const StyledSection = styled.section`
  padding: ${layoutSize[7]} ${layoutSize[3]};
  display: flex;
  flex-direction: column;
`;

const ImageSection = styled.section`
  display: flex;
`;

const ImageColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RestyledSectionTitle = styled(SectionTitle)`
  text-align: left;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;


const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 800px;
`;

const CenteredCopyContainer = styled(CopyContainer)`
  align-self: center;
  text-align: center;
`;

const Copy = styled.p`
  margin-bottom: ${layoutSize[3]};
  font-size: ${fontSize[3]};
  line-height: ${fontSize[7]};
  letter-spacing: 0.48px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ImageContainer = styled.div`
  margin-bottom: ${layoutSize[8]};
  padding: 0 ${layoutSize[4]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${device.tablet} {
    width: 50%;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 2.5px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  overflow: hidden;
`;

const Rumer = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Heath Daniel - Rumer</title>
      <html lang="en" />
      <meta name="Heath Daniel" content="Welcome to my personal portfolio website." />
    </Helmet>
    <Container>
      <PageHeader>
        <PageTitle>Rumer</PageTitle>
      </PageHeader>

      <StyledSection>
          <CopyContainer>
            <Copy>Rumer is a full-stack web app inspired by AirBnB. It allows users to post accomodations which other users can reserve for specific dates.</Copy>

            <Copy>As a host, when you create a listing, it creates a unique page that other users can discover. The listings include a description of the place and a carousel of images of the accommodation.</Copy>

            <Copy>As a guest, when you visit a listing page you can see the dates that it is available and are prevented from making reservations that conflict with any existing reservations.</Copy>
            
            <Copy>This project was built with Node and Express on the backend, React on the frontend, and MongoDB for the database.</Copy>

            <ButtonWrapper>
              <CTAButton Button href="https://rumer.herokuapp.com/">
                See Live
              </CTAButton>
              
              <Button href="https://github.com/hedwardd/rumer">
                Source Code
              </Button>
            </ButtonWrapper>
          </CopyContainer>
      </StyledSection>

      <ImageSection>
        <ProjectImg filename="Rumer_desktop.png" />
      </ImageSection>

      <StyledSection>
        <RestyledSectionTitle>Purpose and Goal</RestyledSectionTitle>

        <CopyContainer>
          <Copy>I built this project to be my first full-stack web application. I see AirBnB as a ubiquitous web platform with a business model that everyone is familiar now. I also chose AirBnB because I see them as a trend-setter , publishing style guides and the like.</Copy>

          <Copy>This project is important to me because it was my first standalone project that I built from A to Z on my own. It marked the first time I had done many things, including using a full-stack monorepo, figuring out hosting, user authentication, user-uploaded content, and more.</Copy>

          <Copy>This project was never meant to actually be used by anyone. I decided to remove the location functionality to speed up development to get some form of an MVP out. I expected to learn a lot and put into practice many of the concepts that I&apos;d learned. I also wanted to build confidence that I could build a web application on my own.</Copy>

          <Copy>The initial designs were to repurpose as much of AirBnB&apos;s UI as much as possible.</Copy>

          <Copy>To begin, I wrote out a bunch of user stories and narrowed them down to the absolute basics. I think I ended up with &quot;As a host, I can post a listing with photos&quot; and &quot;As a guest, I can view a host&apos;s listing and make a reservation for a window of dates on it.&quot;</Copy>
        </CopyContainer>
      </StyledSection>

      <StyledSection>
        <RestyledSectionTitle>Tech Stack and Explanation</RestyledSectionTitle>

        <ContentWrapper>
          <ImageContainer>
            <ImageWrapper>
              <ProjectImg filename="Rumer_mobile.png" />
            </ImageWrapper>
          </ImageContainer>

          <CopyContainer>
            <Copy>This is copy.</Copy>
          </CopyContainer>
        </ContentWrapper>
      </StyledSection>

      <ImageSection>
        <ImageColumn>
          <ImageContainer>
            <ImageWrapper>
              <img src={DatePickerGIF} />
            </ImageWrapper>
          </ImageContainer>
          
          <ImageContainer>
              <ProjectImg filename="Rumer_addListing.png" />
          </ImageContainer>
        </ImageColumn>

        <ImageColumn>
          <ImageContainer>
            <ProjectImg filename="Rumer_listingBrowserFiltered.png" />
          </ImageContainer>

          <ImageContainer>
            <ProjectImg filename="Rumer_listingBrowserFiltered.png" />
          </ImageContainer>
        </ImageColumn>
      </ImageSection>

      <StyledSection>
        <SectionTitle>Problems and Thought Process</SectionTitle>
        <CenteredCopyContainer>
          <Copy>This is copy.</Copy>
          <Copy>This is copy.</Copy>
          <Copy>This is copy.</Copy>
        </CenteredCopyContainer>
      </StyledSection>

      <ImageSection>
        <ProjectImg filename="Rumer_listingBrowserFiltered.png" />
      </ImageSection>

      <StyledSection>
        <RestyledSectionTitle>Lessons Learned</RestyledSectionTitle>

        <CopyContainer>
          <Copy>This is copy.</Copy>
          <Copy>This is copy.</Copy>
          <Copy>This is copy.</Copy>
        </CopyContainer>
      </StyledSection>
    </Container>
  </Layout>
);

export default Rumer;
